import handleHamburgerClick from './globalsHamburger';
import sendCursorPositionToCss from "./globalsCursorPositionStyles";

/**
 * This function initializes all modules that belongs to all pages
 *
 * @function
 */
function initGlobals() {
    sendCursorPositionToCss();
    handleHamburgerClick();
}

initGlobals();
